@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    /*text-align: center;*/
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #2f3442;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

/*.App-link {*/
/*    color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*    from {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    to {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/

.gridArea {
    height: 100vh;
    display: grid;
    grid-template-rows: repeat(auto-fill, 2em);
    grid-template-columns: repeat(auto-fill, 2em);
    grid-gap: 1px;
    background: theme("colors.gray-dark");
    text-align: center;
    justify-content: center;
    position: relative;
}

.gridItem {
    background: theme('colors.white');
}

.gridItem.itemOn {
    background: theme('colors.gray-dark');
}

.gridItem.itemSelected {
    border: 2px solid theme('colors.blue');
}

.gridItem.itemHovered {
    background: theme('colors.gray');
}

.gridItem.pinging {
    background: theme('colors.red');
}

.toolbox {
    position: absolute;
    background: antiquewhite;
}

.toolbox-tool {
    width: 50px;
    height: 50px;
    border: 1px solid theme('colors.gray');
}

.tool-active {
    background: theme('colors.gray-dark');
    color: theme('colors.white');
}

.drag-handle {
    cursor: move;
}
